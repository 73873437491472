//
// utilline
//
// probe lines etc.
import {format} from 'date-fns'
import {ReservationRow, reservationNone, UserType} from './caltypes'
import {is_admin} from './utilrole'
//
//
// ●Line
//  (rowで、link, memoを使ったので, reasonを linkにいれでdebugしていたのを整理した。)
//  (Lineに reasonをつけて、rowの構造は変えない。)
export type Line = {
  empty: boolean; // 一応 init時は trueとして 予約できれば(dbに入れば) falseにする
  enable: boolean; // 今 user が予約 check box を押せるか の属性
  erasable: boolean; // 今 user が削除 check box を押せるか の属性
  text: string; // 表示テキストなので他人の予約は見せられないし、adminなら詳細を知りたいし、というように変化する。
  row: ReservationRow;
  reason: string; // update lines で、理由をメモしておくdebugでもあり、詳細表示にも使うかもしれないので。
  option: string; // @2022-1009 三日目など追加情報が必要になったので。...
}
export const lineNone = {
  empty: true,
  enable: false,
  erasable: false,
  text: '',
  row: reservationNone,
  reason: '',
  option: ''
}
//
const dt_none= new Date('1970-01-01T00:00:00')
//
//
// ●rsub
//
// init/embed/update して rowsから linesを生成して返す。
//
// init_linesした時点では enable, erasableは不明である
// embed_rowした時点では dbから 得た rsで置き換えた lineは emptyではないと断定できる、が
// 埋められなかった lineについては update_linesではじめて userなどから判断する。必要がある。
// userによって返す Line[]の内容がちがうのだが、これはもっと別のところで filterしたほうがいいかも。....(要検討)
//
// 2024 あらためて input UIでの 初期値 option (ラジオ)　の デフォルトを ここで設定する。
//   生成時雛形からの、は option '' で良いが、 rs db-tableからの予約レコード(row)から ここで Lineを初期化するので
//   ここの時点で デフォを 入れておいたほうが良い。でないと UI画面のデフォをクリックしないと line.optionには反映されない。
//   db-tableでの 空についてはここでデフォ（ラジオのデフォ）を 表示するため line2optをしておくべきだ。
//
type RsubType = {
  d: Date;
  rs: ReservationRow[];
  user: UserType;
  debug: boolean; 
}
export  function rsub({d, rs, user, debug}: RsubType) : Line[] {
  const login_user= user.username
  const lines: Line[] = new_lines(d, login_user)
  //
  // embed reservation into line
  // 埋め込み時は line_by_tag()で帰った lineを 参照のように そこへ　置き換えができない。!?
  // --> result.line.xx= yyならいいのでは。...
  // ので lines[pos] ={} している。ややこしい。
  //
  // row.optionはデフォが入っている。dbがなくても new_linesで。
  // ここでは rs (dbの予約) から option
  for(const row of rs){
    const tag = kind2tag(row.kind)
    const pos = tag2pos(tag)
    const option = link2option(row.link, tag)
    
    if(pos<0) {console.log('pos error'); break}

    lines[pos] = {
      empty: false, // dbから得たのだから emptyではない
      enable: false, // 予約が存在するのだから
      erasable: (login_user === row.user || is_admin(login_user)),
      text: '',//row_s(row),
      row: {...row},
      reason: '', // init時も dbからの embed時も reasonは　空。
      option
    }

    //@2022-1105 パッチ 古い予約では 通夜 の linkに '2022-12-10 sogi'  となっている二日デフォしかなかったので
    // このままだと これが三日と解釈され (新しい版では 2022-12-10+sogi で空白で区切って三日とか。。。)
    // ほっておくと tagが undefinedで 削除もできなくなるのでここでパッチ。
    const re_old_link= /^[0-9]{4}-[0-9]{2}-[0-9]{2} sogi$/
    if( re_old_link.test(row.link)){ //bingo!
      
      const new_link= row.link.replace(' ', '+')
      lines[pos].row.link= new_link
      console.log(`@@@rsub patch '${row.link}'`)
      console.log(lines[pos])
    }


    //ここでは下の findものより 上の pos のほうが明解かな。
    // const t = line_by_tag(tag, lines)
    // if(t.success){
    //   t.line.empty= false
    //   t.line.enable= false
    //   t.line.erasable= (login_user === row.user || is_admin(login_user))
    //   t.line.text= row_s(row)
    //   t.line.row= {...row}
    //   t.line.reason= ''
    // }
    // console.log('@@@', t.line)
  }
  //
  // 〇更新処理
  // 埋め込んだあとで linesの中だけで probesを作って判定している。
  // 埋め込まれたものを rs, それ以外の tagを try_tagsとして probesをえて、
  // linesの各行で影響のあるなしをみている。
  // だから この rsub全体としては rsがそもそもなければ init_lines結果が updateをとおしても変化せず返る。
  // 
  if(debug) console.log(`${format(d, 'MMdd')} ------------------------------------(@rsub)`)
  update_lines(lines, d, login_user, debug)
  //
  // 作成したlineの部分を見せる、全部を見せるなどの処理をして返す。
  //
  //管理者にはすべて
  if(is_admin(user.username)){
    return lines
  }
  // 檀家には法要スケジュールのみ
  else if(user.danka){
    return lines.filter( line => line.row.kind.startsWith('法要'))
  }
  // 業者には法要以外を
  else if(user.funeral){
    return lines.filter( line => ! line.row.kind.startsWith('法要'))
  }
  //undefinedのときはとりあえずすべて
  else{
    return lines
  }
}

//
// ●id3  1 --> '001', -2 --> ' -2' (padStartが使えるようになってきた)
//
export function id3(id: number){
  //const id: string= (row.id === undefined || row.id=== -1) ? 'unk' : row.id.toString().padStart(3, '0')
  const s: string= (id < 0)
    ? id.toString().padStart(3, ' ')
    : id.toString().padStart(3, '0')
  return s
}
//
// ' -1 2022-04-09 18:05:40 金蓮寺会館 葬儀1 [7 13)' -->いろいろ検討中
//
// export function row_s(row: ReservationRow){
//   //const kind: string= row.kind.padStart(3)
//   // linkについては未定
//   const s= 
// `${id3(row.id)} ${row.date} ${row.facility} ${row.kind} [${row.start} ${row.end})  \
//   ${row.user} ${row.memo} ${row.link}`
//   return s
// }
export function row_s(r: ReservationRow){
  // kind 6 charsだが漢字半角交じ　りになるのできれいにはならん。
  const kind= r.kind.padStart(5)
  const user= r.user.padStart(8)
  // 2022-01-01 tag tagは6charsで。
  const link= r.link.padStart(10+1+6)
  const dt= new Date(r.date)
  //const date= format(dt, 'yyyy-MM-dd HH:mm:ss')
  const date= format(dt, 'yyyy-MM-dd')
  const s= `${r.id}|${r.facility}|${kind}|${user}|${link}|${date}`
  return s
}
//
export function row_s2(r: ReservationRow){
  //const pre= '        '
  const s= `${r.funeral_tuya}|${r.funeral_owner}|${r.funeral_start}|${r.funeral_end}|${r.funeral_denomination}|\
    ${r.hoyo_nenki}|${r.hoyo_misc}|${r.hoyo_at}|${r.hoyo_owner}|${r.hoyo_addr}|${r.hoyo_phone}`
  return s
}
//
// ●p_lines
//
export function p_lines(msg: string, lines: Line[]){
  //`空き 予約可 削除可 ...`
  const header=`empty enable erasable --------- ${msg} ${lines.length} lines. ---------`
  console.log(header)
  //
  for(const l of lines){
    //l.text = row_s(l.row) //テキスト部は表示用なのでまだ確定しないのでrow_s()を見直す。
    // 幅が微妙にことなるのでフォントの影響か、ダミーのspをバツの後にだけ、いれる。
    const symbols = `${l.empty?'●':'✕ '}${l.enable?'●':'✕ '}${l.erasable?'●':'✕ '}`
    const text = row_s(l.row)
    const reason= l.reason.padStart(20)
    const s= `${symbols} ${text}<${reason}`
    const s2= row_s2(l.row)
    console.log(s)
    console.log(s2)
  }
}
//
// ●p_rows
//
export function p_rows(msg: string, rows: ReservationRow[]){
  console.log(`${msg}`)
  for( const row of rows ){
    console.log(row_s(row))
    console.log(row_s2(row))
  }
}
//
// ●rows_tags_s  rowsの中から kind-->tag を抜き出して 簡易な一覧を表示するため
//
export function rows_tags_s(rows: ReservationRow[]){
  const tags= rows.map( o => kind2tag(o.kind) )
  return tags.join(', ')
}
//
// ●seType ●intersect (probeのためのutility)
//
// 半開区間を想定を想定した start/end の型
// start/end　からの intersect配列の生成
//
interface seType {
  start: number; end: number;
}
function intersect_se(o1: seType, o2: seType){
  const to_a= (o: seType) =>{
    const a: number[]= []
    for( let i= o.start; i< o.end; i++ ) a.push(i)
    return a
  }
  const a1 = to_a(o1)
  const a2 = to_a(o2)
  const r = a1.filter( n => a2.includes(n) )
  return r
}
//
// ●probe 制約 衝突 について
//
// これから挿入しようとする予約レコード(stone)と
// 同じ日の予約一覧配列(dbにある予約レコード)との間に時間帯の intersect があれば 衝突とみなす。
// 
// 衝突があれば ProbeType の配列を返す。
// なければ conflicts.length はゼロ、である。
//
// start/end 情報だけで 衝突を探すので レコードの日付は見ていないことに注意。
// -->@2022-1101 facilityで 例外救済をやめられるか。法要室葬儀と法要at 金蓮寺をさばけるか。
//
export type ProbeType = {
  stone_kind: string; 
  id: number; // stoneと衝突する rowsの id
  kind: string;
  intersects: number[]; // 衝突する時間帯
  msg: string;
}
//
// rowsが 0 lengthなら probes [] が返るだけ。
//
//
function p_probe(p: ProbeType){
    const stone_tag= kind2tag(p.stone_kind)
    const stone_facility= tag2facility(stone_tag)
    const tag= kind2tag(p.kind)
    const facility= tag2facility(tag)
    return `${p.stone_kind} ${stone_tag} ${stone_facility} <--> ${p.kind} ${tag} ${facility}`
    // intersect:${p.intersects.join(' ')}
}
//
export function p_probes(msg: string, probes: ProbeType[]){
  console.log(`${msg}`)
  for(const p of probes){
    console.log(`${p_probe(p)}`)
  }
}
//
// ●probe stone 制約情報ProbeType[]を返す。
//
export function probe_stone( stone: ReservationRow, rows: ReservationRow[], debug: boolean): ProbeType[]{
  const is_hoyo= (t:string) => t.startsWith('hoyo')
  const is_sogiK= (t:string) => (t==='tuya'||t==='sogi'||t==='anti')
  const is_sogiH= (t:string) => (t==='tuya-h'||t==='sogi-h'||t==='anti-h')
  // 2024 new, L(ホール) and A(安置室)
  const is_sogiL= (t:string) => (t==='tuya-l'||t==='sogi-l'||t==='anti-l')
  const is_sogiA= (t:string) => (t==='tuya-a'||t==='sogi-a'||t==='anti-a')

  // result
  const probes: ProbeType[] =[]
  //
  const stone_tag= kind2tag(stone.kind)
  const stone_se: seType = { start: stone.start, end: stone.end }

  //
  // rows loop
  //
  for(const {id, kind, start, end, facility} of rows){
    const tag= kind2tag(kind) // stoneについて この日の line tag を個々に調べて衝突(intersect)があれば pushしている。
    //const ymd= date.slice(5,10)// MMdd

    // if(debug){
    //   console.log(`stone: ${stone_tag} ${stone.facility} --> tag: ${tag} ${facility} `)
    // }
    let zero_reason= '(衝突なし)' // 衝突判断しなくてよい理由 または判断したけどintersectsがない。
  
    let intersects: number[]= []
    //  会館葬儀 での衝突をみる
    switch(stone_tag){
      case 'tuya':
      case 'sogi':
      case 'anti':
        if(is_sogiK(tag)) {intersects= intersect_se(stone_se, {start, end})}
        else { zero_reason= `1.無視 これは常に葬儀と衝突しない` }
        break
      
      case 'tuya-h':
      case 'sogi-h':
      case 'anti-h':
        // 全部 facilityでみてもいいかも。法要室を使う法要の場合も衝突をみる。
        if(is_sogiH(tag) || facility==='法要室') {intersects= intersect_se(stone_se, {start, end})}
        else { zero_reason= `2.無視H これは葬儀Hタグでないあるいは、法要室でないので衝突しない`}
        break

      case 'hoyo9':
      case 'hoyo10':
      case 'hoyo11':
      case 'hoyo12':
      case 'hoyo13':
      case 'hoyo14':
      case 'hoyo15':
      case 'hoyo16':
        // 法要同士なら普通に.
        if(is_hoyo(tag)){intersects= intersect_se(stone_se, {start, end})}
        else { zero_reason= `3.無視 これは常に法要タグとは衝突しない`}
        break
      // 2024 L, A 追加。
      case 'tuya-l':
      case 'sogi-l':
      case 'anti-l':
        if(is_sogiL(tag)){intersects= intersect_se(stone_se, {start, end})}
        else { zero_reason= `1.無視 これは常に葬儀と衝突しない` }
        break
      
      case 'tuya-a':
      case 'sogi-a':
      case 'anti-a':
        if(is_sogiA(tag) || facility==='安置室') {intersects= intersect_se(stone_se, {start, end})}
        else { zero_reason= `2.無視A これは葬儀Aタグでないあるいは、安置室でないので衝突しない`}
        break
    
      default:
        console.log(`@@@probe_stone 条件判断漏れエラー ${stone_tag} <--> ${tag}`)
        break
    }

    if(0<intersects.length){
      const msg =`NG ${stone.kind} <--> ${kind} ${intersects.join(' ')}`
      const probe= {stone_kind: stone.kind, id, kind, intersects, msg}
      probes.push(probe)
      if(debug) console.log(`@衝突`, p_probe(probe))
    }else{
      if(debug) console.log(`OK ${zero_reason} (stone: ${stone_tag} ${stone.facility} --> tag: ${tag} ${facility})`)
    }

  }

  
  return probes
}

//
// ● ... tagでuniqueになるはずなので rowsを 指定 tagのrowと それ以外の rowsにわける
//
export function separate_rows( tag: string, rows: ReservationRow[]){
  const kind= tag2kind(tag)
  const result = rows.find( x => x.kind === kind)
  if(result === undefined){
    console.log(`${tag} not found`)
    return {stone: reservationNone, rows: []}
  }
  const stone= {...result}
  const others = rows.filter( x => x.kind !== kind)
  return {stone, rows: others}
}
//
// ●手動で(dbとは関係なく)レコードを作るときに仮idを振るのに必要なだけ。
//_tag_kind
export function max_id(rows: ReservationRow[]) : number {
  const initialRow= reservationNone
  initialRow.id= 0
  const r= rows.reduce( (prev, curr) => prev.id > curr.id ? prev: curr, initialRow)
  return r.id
}
//
// 〇_tag_kind pos, tag, kindの相互変換用
//
// (linesのindexをposとして持ってみる)(linesはあくまでarrayにしたいので連想はやめる)
// (tagは code向けなのでdbには出ない, kind文字列が実際にdbに書き出される)
// H 法要室を追加
// facility: で 会館か法要室か、をわけてみる
// ただし 法要は 自宅もあるので予約が入るまでわからない。予約の hoyo_at を見るしかない。
const _tag_kind : {
  pos: number;
  tag: string;
  start: number,
  end: number,
  kind: string,
  facility: string,
}[] = [
  {pos: 0, tag: 'tuya', start: 19, end: 24, kind: '通夜', facility:'会館'},
  {pos: 1, tag: 'sogi', start: 7, end: 19, kind: '葬儀', facility:'会館'},
  {pos: 2, tag: 'anti', start: 7, end: 19, kind: '安置', facility:'会館'},
  {pos: 3, tag: 'tuya-h', start: 19, end: 24, kind: '通夜H', facility:'法要室'},
  {pos: 4, tag: 'sogi-h', start: 7, end: 19, kind: '葬儀H', facility:'法要室'}, 
  {pos: 5, tag: 'anti-h', start: 7, end: 19, kind: '安置H', facility:'法要室'}, 
  // 2024 newly added. 割り込ませる re-index pos
  {pos: 6, tag: 'tuya-l', start: 19, end: 24, kind: '通夜L', facility:'ホール'},
  {pos: 7, tag: 'sogi-l', start: 7, end: 19, kind: '葬儀L', facility:'ホール'}, 
  {pos: 8, tag: 'anti-l', start: 7, end: 19, kind: '安置L', facility:'ホール'}, 
  {pos: 9, tag: 'tuya-a', start: 19, end: 24, kind: '通夜A', facility:'安置室'},
  {pos: 10, tag: 'sogi-a', start: 7, end: 19, kind: '葬儀A', facility:'安置室'}, 
  {pos: 11, tag: 'anti-a', start: 7, end: 19, kind: '安置A', facility:'安置室'}, 
  //
  {pos: 12, tag: 'hoyo9', start: 9, end: 10, kind: '法要9時', facility:''},
  {pos: 13, tag: 'hoyo10', start: 10, end: 11, kind: '法要10時', facility:''},
  {pos: 14, tag: 'hoyo11', start: 11, end: 12, kind: '法要11時', facility:''},
  {pos: 15, tag: 'hoyo12', start: 12, end: 13, kind: '法要12時', facility:''},
  {pos: 16, tag: 'hoyo13', start: 13, end: 14, kind: '法要13時', facility:''},
  {pos: 17, tag: 'hoyo14', start: 14, end: 15, kind: '法要14時', facility:''},
  {pos: 18, tag: 'hoyo15', start: 15, end: 16, kind: '法要15時', facility:''},
  {pos: 19, tag: 'hoyo16', start: 16, end: 17, kind: '法要16時', facility:''},

]
/*
2022-0820 以下を改定した ので上記参照
  {pos: 0, tag: 'sogi', start: 7, end: 19, kind: '葬儀'},
  {pos: 1, tag: 'anti', start: 7, end: 19, kind: '安置-葬儀'}, // 翌日の葬儀との連携でのみ存在する
  {pos: 2, tag: 'tuya', start: 19, end: 24, kind: '通夜-葬儀'}, // 翌日の葬儀との連携でのみ存在する
  {pos: 3, tag: 'hoyo9', start: 9, end: 10, kind: '法要9時'},
*/
/*
  @2022-1027より... 法要室の葬儀を新設。仕組み(tuya-sogi-...)は同じだが、
  法要各種とは排他しなければならない。
*/


//
// タグ一覧...何に？-->new_lines()で初期化セット作成。
//
export function tags_(){
  const tags=[]
  for(const o of _tag_kind){
    tags.push(o.tag)
  }
  return tags
}
//
// 〇tag2start, end
//
function tag2start(tag: string){
  const result= _tag_kind.find( o => o.tag === tag)
  return result === undefined ? -1: result.start
}
function tag2end(tag: string){
  const result= _tag_kind.find( o => o.tag === tag)
  return result === undefined ? -1: result.end
}
//
// 〇tag2kind
//
export function tag2kind(tag: string){
  const result = _tag_kind.find( o => o.tag === tag )
  return result === undefined ?  '種別不明' :  result.kind
}
//
// 〇kind2tag
//
export function kind2tag(kind: string){
  const result = _tag_kind.find( o => o.kind === kind )
  return result === undefined ?  'unknown' :  result.tag
}
//
// 〇tag2pos
//
export function tag2pos(tag: string){
  const result = _tag_kind.find( o => o.tag === tag )
  return result === undefined ?  -1 :  result.pos
}
//
// 〇tag2facility
//
export function tag2facility(tag: string){
  const result = _tag_kind.find( o => o.tag === tag )
  return result === undefined ? '' : result.facility
}

//
// ●YoyakuType 予約レコードの型
//
// 各種予約レコードをつくって eventを投げるための ReservationRowを作る
// 予約レコードにいれる日付形式はこのようにLocaltime で(Zなし) 時刻はカレンダーでのそれ、なので
// 00:00:00 start of dayになっているはずである。必要ならここで alignしないと、予約レコードでの決まりとして。
// 予約情報としては username しかdbに記録されないので passwordファイルで管理している内容とは一致しない。
export type YoyakuType ={
  //ev: string; // insr, selr, delr ... operation delrは idをとるのでまとまるか？
  tag: string; //種別は固定数 see _tag_kind
  dt: Date;
  user: string;
}
//
// ●yoyaku   ReservationRow を生成する。(k3.db対応のため変更)(userを外す!?)
//
// 例
// yoyaku {tag:'sogi1', dt: new Date(), user:'admin'}
//
export function yoyaku({tag, dt, user}: YoyakuType): ReservationRow {

  // @2022-1027,1101 _tag_kindに facilityデフォをいれた。
  // 法要は デフォ 空 なので UIで金蓮寺選んだときだけ '法要室' としてはいる、ここではない。いいのか？2024 再考中...

  const r: ReservationRow = {
    id: -1,
    date: format(dt, 'yyyy-MM-dd HH:mm:ss'),
    facility: tag2facility(tag),
    kind: tag2kind(tag),  // sogi --> 葬儀 など
    user,
    start: tag2start(tag),
    end: tag2end(tag),
    link: '',
    memo: '',
    funeral_tuya: '',
    funeral_owner: '',
    funeral_start: '',
    funeral_end: '',
    funeral_denomination: '',
    funeral_wake: "",
    funeral_memo: "",
    memorial_start: "",
    coffin_start: "",
    hoyo_nenki: '',
    hoyo_misc: '',
    hoyo_at: '',
    hoyo_owner: '',
    hoyo_addr: '',
    hoyo_phone: ''
  }
  return r
}

//
// 既存予約(rs)がない状態でのつまり、初期の画面を模擬する
// ●a line
// ●new lines
// (予約画面、なのでuserをログインのuserで初期設定している。)
// (予約実行時にいれてもいいが...)
// (このあとembed するときは当然他人の 予約をかぶせていくことになる。)
//
// (2024 )
function a_line(tag: string, dt: Date, user: string){
  const row= yoyaku({tag, dt, user})
  const text= ''//row_s(row)
  const option= row_option_default(tag)
  
  //
  const line: Line = {
    empty: true,
    enable: true,
    erasable: false,
    text,
    row,
    reason: '',
    option,
  }
  return line
}
//
// あたらしい lines つまり、初期値(a_line)による１画面。db反映は無い状態。
//
function new_lines(dt: Date, user: string ){

  const tags= tags_() // 全タグだけど、ここを変えれば 画面は変えられる。
  const lines: Line[] = []
  //
  for(const tag of tags){
    lines.push( a_line(tag, dt, user) )
  }  
  return lines
}



//
// ●update disables by probe tags
//
//  1. lines から 予約ありの rows を作る。現時点の dbの内容である。
//  2. lines から 予約なしの tags を作る。
//  3. 予約のない tag のレコードについて 既存のレコードとの　衝突がないか probe する。
//  (予約のないtag が stone であり rowsに対して probe_stone する)
//  4. 予約のないタグ全部に対して 3. を繰り返す。
//  5. probesから 衝突する tags を得て、 Lines の該当タグを disable にする。
//  6. erasableを更新する。(衝突に関係ないけど...)
//
//   これで empty だが disable なレコードを更新できたことになる。
//   dbからの 予約情報が入る毎にこれを繰り返せばよい。
//
export function update_lines(lines: Line[], dt: Date, login_user: string, debug: boolean){
  // 現時点での予約レコードの配列をえてprobeする
  const rows = lines.filter( o => o.empty === false ).map( o => o.row )
  const reserved_tags: string[] = rows.map( o => kind2tag(o.kind) )
  const try_tags: string[] = tags_().filter( tag => ! reserved_tags.includes(tag) )

  //if(debug){console.log(`@update_lines ${format(dt, 'yyyy-MMdd')} rows.length=${rows.length}`)}

  //
  // discover empty but disable tags
  // 衝突判定材料ができたので probe して (emptyなのに)予約できないものをリストする。
  // intersectと、 例外条件で。いや、やはり probe_stone()内でつまり奥底で判定しておかないと破綻する。かな。...
  //
  const disable_tags=[]
  for(const tag of try_tags){
    const stone = yoyaku({tag, dt, user: login_user})
    const probes = probe_stone(stone, rows, debug)
    
    if(0<probes.length){
      disable_tags.push(tag)
    }
    // if(debug && 0<probes.length ){
    //   p_probes('@update_lines', probes)
    // }
  }

  if(debug && 0<disable_tags.length){
    console.log(`@update_lines ${format(dt, 'MMdd')} disable_tags: ${disable_tags.join(' ')}`)
  }

  //
  // 1. 予約があるレコード(!empty) については enable/erasable はそのまま...ではなく初期値は削除不可なのでここで更新!
  // 2. 衝突したタグは (emptyでも) disableにする。 これが狙い。
  // 3. 衝突しないタグは(emptyなら) enable/erasableは 初期状態 true/false にresetしておく。
  // 4. 以上。つまり 1..3以外って？
  //
  for(const line of lines){
    const tag = kind2tag(line.row.kind)
    //
    // 1. dbからレコードが埋められている場合
    //
    if( ! line.empty ){
      if(line.enable){
        console.log(`${line.row.kind} 予約ありレコードがenableなのはなぜ。row user ${line.row.user}, login ${login_user} `)
        //console.log('@@@', line.row)
      }
      // なにもさわらない 既存予約レコードなので...いやここで 削除を設定更新!?
      // しかし erasable をupdate時に admin 特例するとしたらここしかない。
      // updateをかけるときの userは ログインのそれ、 予約レコードは dbのそれだから注意。
      // line生成時はデフォでは 無条件で falseなので updateでちゃんと毎回更新。
      line.erasable =  login_user === line.row.user || login_user === 'admin'
      // ログインユーザーが admin なら無条件で erasableとする
      line.reason= `${login_user}/${line.row.user} ${line.erasable ? '削除可':'削除不可'}`
    }
    //
    // 2. probeして disable_tags をこの行について判断する必要がある場合
    //
    else if(disable_tags.includes(tag)){
      // 衝突しているタグは(emptyだけど) disable予約不可にしなければならない これが主たる目的
      line.enable = false
      line.reason= '予約不可 制約により'
    }
    //
    // 3. 制約(disable_tags) がない。
    //
    else if(line.empty){
      line.enable = true
      line.erasable = false
      line.reason= `制約なし flags reset`
    }
    //
    // 4. その他があればバグ
    //
    else{
      console.log(`@@@これは無いはず!?@@@ tag=${tag}`)
    }
  }
} // update_lines 

//
// ●check_line_pos
//
//  固定配列なので初期化されているかどうか tagで決まる位置が正しいindex範囲かをみる。
// export type LineResult = {
//   success: boolean;
//   msg: string;
//   line: Line;
// }
// export function line_by_tag(tag: string, lines: Line[]): LineResult {
//   if(tag === ''){
//     return { success: false, msg: 'tagが空', line: lineNone}
//   }
//   if(lines.length===0){
//     return { success: false, msg: 'linesが空', line: lineNone}
//   }
//   //
//   const pos= tag2pos(tag)
//   if(pos<0){
//     return { success: false, msg: `'${tag}' の位置が不明`, line: lineNone}
//   }
//   return { success: true, msg: 'OK', line: lines[pos] }
// }
//
// こいつがエラーなのは深刻なのでthrowするversion
//
export function line_by_tag(tag: string, lines: Line[]) : Line {
  
  if(tag === undefined) throw new Error('line_by_tag: tagが 未定義です')
  if(tag === '') throw new Error('line_by_tag: tagが空です。')
  if(lines.length===0) throw new Error('line_by_tag: linesが空です。')
  const pos= tag2pos(tag)
  if(pos<0) throw new Error(`line_by_tag: ${tag} の位置が不明。`)
  return lines[pos]
}
//
// ●is_empty  Lineのempty属性は予約が無いことをしめすが、重複以外の制約はありうるので enableが別にある
//
export function is_empty(tag: string, lines: Line[]){
  const target= lines[tag2pos(tag)]
  return target.empty
}
//
// ●is_exists   Lineのemptyの否定は dbからの既存予約であることを示す
//
export function is_exists(tag: string, lines: Line[]){
  const target= lines[tag2pos(tag)]
  return !target.empty
}


//
// ●check insr
//
export function check_insr(tag: string, line: Line) {
  if(! line.empty){
    const msg=`${line.row.kind} はすでに予約があるので追加できません `
    return {success: false, msg}
  }
  if(! line.enable){
    const msg= `${line.row.kind} は制約のため追加できません`
    return {success: false, msg}
  }
  return {success: true, msg: `OK ${tag} は追加できます`}
}
//
// ●check delr
//
export function check_delr(tag: string, line: Line){
  if(line.empty){
    const msg=`${line.row.kind} は予約が無いので削除できません`
    return {success: false, msg}
  }
  if(! line.erasable){
    const msg= `${line.row.kind} は制約のため削除できません`
    return {success: false, msg}
  }
  return {success: true, msg: `OK $${tag} は削除できます`}
}

//
// date tag を取り出す
//
export function chop_date(str: string):{success:boolean, dt: Date} {
  const pattern = /\d{4}-\d{2}-\d{2}/
  const result= str.match(pattern)
  return result===null
    ? { success: false, dt: dt_none }
    : { success: true, dt: new Date(`${result[0]} 00:00:00`)}
}
// date文字列はよけて、タグ文字を得る。小文字しかつかってないのでこれでいい。sogi1とか...
export function chop_tag(str: string): string{
  const pattern = /[a-z]+[a-z0-9]+/
  const result = str.match(pattern)
  return result === null ? '' : result[0]
}
// 2022-06-30 tuya1 ==> dt, tagにする
// tuya1 2022-06-30 ==> dt, tagにする
// 日付部がなければ successは false
// タグがなければ 空文字 '' となる
export function chop_dt_tag(link: string):{success: boolean, dt: Date, tag: string}{
  const {success, dt} = chop_date(link)
  const tag = chop_tag(link)
  return {success, dt, tag}
}
//
// ●get_link
// あれば link部の '2022-06-29 sogi1' から dt, tagを返す。
// ここに渡すlineは tagから check_line_pos(tag, lines)で得ておけばいいはず。
export type LinkResult = {
  success: boolean;
  msg: string;
  dt: Date;
  tag: string;
}
export function get_link(tag: string, line: Line): LinkResult {
  // row.linkは必ず存在し '' で初期化されている。
  if( line.row.link === '' ){
    const msg = `link はありません`
    return {success: false, msg, dt: dt_none, tag: '' }
  }
  //
  // link先のtagは tag2として得る。
  //
  const {success, dt, tag: tag2} = chop_dt_tag(line.row.link)
  if(!success){
    const msg= `link から日付とタグが取れません ${line.row.link}`
    return {success: false, msg, dt: dt_none, tag: '' }
  }
  //
  // OK link部の日付とタグを返す。
  //
  return {success: true, msg: `OK ${line.row.link}`, dt: dt, tag: tag2}
}
//
//   '2022-10-10+sogi 2022-10-11+anti' =>[
//    {dt: Date; tag: string;}, ...
//   ]
//
export function dt_tag(s: string){
  const links= s.split(' ')
  const result=[]
  for(const link of links){
    const [ymd, tag] = link.split('+')
    const dt = new Date(`${ymd}T00:00:00.000Z`) // as UTC
    result.push({dt, tag})
  }
  return result
}
//
// ● line.link と row.options を デフォを考えてちゃんと、する。
// linkは yyyy-MM-dd+sogi とか yyyy-MM-dd+sogi-h とかだった。
//  tuya -- 二日 三日 四日 (デフォは '二日')
//  tuya-h -- 二日 三日 四日 (デフォは '二日')
//  tuya-l -- 1日葬儀 2日葬儀 1日法事 (デフォ '1日葬儀')
//  tuya-a -- 1日 2日 3日 4日 (デフォ '1日')
//
//  hoyo* -- 自宅 金蓮寺 その他 なのだが...これはそのままがいいかな、仮予約ばっかりだたし...


function row_option_default(tag: string){
  switch(tag){
    case 'tuya': return '二日'
    case 'tuya-h': return '二日'
    case 'tuya-l': return '1日葬儀'
    case 'tuya-a': return '1日'
    default: return ''
  }
}

function link2option(link: string, tag: string){
  switch(tag){
    case 'tuya': return link2optionK(link)
    case 'tuya-h': return link2optionH(link)
    case 'tuya-l': return link2optionL(link)
    case 'tuya-a': return link2optionA(link)
    default: return '' //そのままの link を optionに というわけには？
  }
}

//
// 空文字のsplit(' ').lnegth は 1 だ。 '二日'.split(' ').lengthも 1なので....
// 空文字の場合は '1日' とするか。デフォはともかく。
// db 予約の row.linkを calで読めた場合に 対応する line.option を返す。
function link2optionK(link: string): string {
  
   if( link === '') return '二日' //以前のdbでは link空文字はありうる。

  //予約があるとき linkの空白区切りで 長さをはかる
  const length= link.split(' ').length
  switch(length){
   case 1: return '二日'
   case 2: return '三日'
   case 3: return '四日'
   default:
    console.log('link2optionK error')
    return '' //エラーだが...
  }
}
// とりあえずKとHは同じものを使っていたのだが関数は分ける、処理はまだ同じ
function link2optionH(link: string): string {
  //予約がないときは row.link は '' 空文字 これは デフォと対応させねば
   if( link === '') return '二日'

  //予約があるとき linkの空白区切りで 長さをはかる
  const length= link.split(' ').length
  switch(length){
   case 1: return '二日'
   case 2: return '三日'
   case 3: return '四日'
   default:
    console.log('link2optionH error')
    return '' //エラーだが...
  }
}
//
// 2024 new L, A
//
function link2optionL(link: string): string {

  // 以下はどれも split lengthが 1なのでまず判断。
  if( link === '') return '1日葬儀'
  if( link === '1日葬儀' ) return '1日葬儀'
  if( link === '1日法事' ) return '1日法事'

  const length= link.split(' ').length
  // 2日分リンクがあるはずなので ( 空文字はもう判定済みなので 1を2日としてよい)
  if(length === 1) return '2日葬儀'


  console.log(`lik2optionL error`)
  return '' //エラーだけど
}

//
// A 安置の 期間 option は 1,2,3,4日
//
function link2optionA(link: string): string {
  
  if( link === '') return '1日'

  const length= link.split(' ').length
  
  switch(length){
    case 1: return '2日'
    case 2: return '3日'
    case 3: return '4日'
    default:
      console.log('link2optionA error')
      return '' //エラー
  }
}

// end utilline.ts