//
// calendar.tsx
//
import './calendar.scss'
import React from 'react'
//import {FontAwesomeIcon as Fa} from '@fortawesome/react-fontawesome'
import {Flags, Context} from './App'
import {format, isBefore, addMonths, subMonths, isToday, isFirstDayOfMonth,
  isSameDay, addDays} from 'date-fns'
import {DayForm} from './dayform'
import {hms} from './helpers'
import {Line,  kind2tag, line_by_tag, rsub, dt_tag, p_rows} from './utilline'
import {
  UserType, ReservationRow, CalType, ItemDType, itemDNone, ReservationDay,
  ConstraintDay,
} from './caltypes'
import {is_admin} from './utilrole'


// inboxは棺桶風？かと思ったがわかりにくい、でかくしても。文字 '安置' のほうがいいだろう
//const FasInbox = ()=> <Fa icon={['fas', 'inbox']} className="fa-2x" />

//
// container-fluid の paddingを変える。bootstrapのデフォは8くらいか。
//
const s_padding1= { padding: "1px"}
//
// cal応答の構造は複雑なので dbの rowとからめて 末端から 型整理する。sigh...
// 以下。
//
// ●emit cal の応答の型 -->calendarのsubscribeで 受信したObjectの型。
// ●1日分の 予約レコード (dbのカラムそのままである)
// また 日付はsqliteではTEXTなのだが想定はDate UTCであり、kqは UTCで Dateオブジェクトを emitするようにしている、
// のでここで受けたときは UTCのISO文字列で受けることになる、Rawである。すぐあとで date_itして DateオブジェクトにしているのがCalTypeだ。
//
type _ReservationDay = {
  d: string; // 日付のISO文字列utcでの。
  rs: ReservationRow[];
}
 type _ReservationWeek = _ReservationDay []
 type _CalType = {
  weeks: _ReservationWeek[];
  begin: string;
  end: string;
  start: string;
  last: string;
  endDay: ReservationDay;
}
//@2022-1003 'cal2' 応答追加
type _Cal2Type = {
  cal1: _CalType,
  cal2: _CalType
}
//
//●DayRs 部品
// 

// 2024整理した結果
 //
// 日付行は表示されたあと <DayRs/> となる
// 1 tuya, sogi, anti 会館 K
// 2 tuya-h, sogi-h, anti-h 法要室 H
// 3 tuya-l, sogi-l, anti-l  ホール L  (新しい金蓮寺ホールでの葬儀と法要)
// 4 tuya-a, sogi-a, anti-a  安置室 A (新しいミニホールでの安置)
// 5 hoyoN 法要  (これは従来通り 件数で。)
//


// 2024 場所 優先表示 による １行目から...
// 日単位なので 連携(link) は考えなくてよい。
// db予約情報(ReservationRow)には kind はあっても tag はない。
// kindとtagは いちおう関数を通して対応を取るようにしているので注意。直接ではなく
// あくまで tag2kind()などを経由する。ここでは kind規程に従うのでkind文字列で考える。
// (制約などでは tag にして考えているのだけど db場所優先表示なので tagを経由する必要はない)

//
// <LineRs cat={catK} rs={rs} />
//
const catK: string[] = ['通夜', '葬儀', '安置']
const catH: string[] = ['通夜H', '葬儀H', '安置H']
const catL: string[] = ['通夜L', '葬儀L', '安置L']
const catA: string[] = ['通夜A', '葬儀A', '安置A']
// 2024 法要室 で(食事) ってのは考え中
const LineRs =({cat, rs}:{cat: string[]; rs: ReservationRow[];}) => {
  const rows= rs.filter( r => cat.includes(r.kind) )
  if(1<rows.length){console.log('@@@LinesRs error', rows)}
  return (<>
    {rows.length===0 ? <span>&nbsp;</span> : <span>{rows[0].facility}</span>}
  </>)
}

//
// ●DayRs その日、１日につき 行ごとに 場所facility 利用有無を表示
//
export const DayRs = ({user, rs}:{user: UserType; rs: ReservationRow[];}) => {
 
  //
  // hoyo_atが金蓮寺の場合、hoyo_addrに '法要後寺内にて会食希望' とあれば 場所としての法要室と出したい。
  // 
  //
  const line5 = () => {
    // @2022-1003 ログイン檀家の法要予約があればその名前を表示する、でなければ従来のように件数。
    const hoyo_rs= rs.filter(r => /^法要/.test(r.kind))
    const found = hoyo_rs.find( r => r.user === user.username )
    const hoyo_msg = (found && user.danka) ? `${found.user}様 法要` : `法要:${hoyo_rs.length}件`
    if(hoyo_rs.length === 0) return (<span>&nbsp;</span>)
    else return (<span>{hoyo_msg}</span>)
  }
  const kaishoku = () => {
    // @2022-1003 ログイン檀家の法要予約があればその名前を表示する、でなければ従来のように件数。
    const hoyo_rs= rs.filter(r => /^法要/.test(r.kind))
    //2024 法要室で会食を とりあえず line5にて。
    const kaishoku_rs= hoyo_rs.filter( r => r.hoyo_addr.includes('会食') )
    const kaishoku_msg: string = kaishoku_rs.length === 0 ? '' : '法要室会食'
    return kaishoku_msg   
  }
  const hall_link = () => {
    const hall_rs = rs.filter(r => catL.includes(r.kind))
    // ホールで linkが空なのは 葬儀2日目、と断定してよい。
    if(hall_rs.length === 0) return ''
    // 予約がありlinkがある。
    const link= hall_rs[0].link
    if(link.includes('sogi-l')) return '葬儀'
    else if(link==='') return '葬儀' //葬儀の2日めは rsが存在し、linkが空。sigh...
    else return link
  }

 

  return (<>
    <div className="line2">
      <LineRs rs={rs} cat={catK} />
    </div>
    <div className="line2">
      <LineRs rs={rs} cat={catH} />
      <span>{kaishoku()}</span>
    </div>
    <div className="line2">
      <LineRs rs={rs} cat={catL} />
      <span>{hall_link()}</span>
    </div>
    <div className="line2">
      <LineRs rs={rs} cat={catA} />
    </div>
    <div className="line2">{line5()}</div>

  </>)
}
//-------------------------------------------------------------------------------
//
// ●Calendar  (<--DayRs, DayFormを使うひと月分のカレンダー表示)
//
type CalendarProps = {
  flags: Flags;
  setFlags: React.Dispatch<React.SetStateAction<Flags>>;
  user: UserType; // ログインユーザー
}
export const Calendar = ({user, flags, setFlags}: CalendarProps) => {
  const{socket} = React.useContext(Context)  
  // prev/nextするのでentryは1日にalignしておく。いや、-->serverで計算するのでそのまま使える。
  const [entry, setEntry] = React.useState( new Date() )
  const [calendar, setCalendar] = React.useState<CalType>({
    weeks:[],
    begin: new Date(),
    end: new Date(),
    start: new Date(),
    last: new Date(),
    endDay: {d: new Date(), rs: []}
  })
  const [cdays, setCdays] = React.useState<ConstraintDay[]>([])
  const [itemD, setItemD] = React.useState<ItemDType>(itemDNone)
  const [stamp, setStamp] = React.useState({ at: hms() })
  const [lines, setLines]= React.useState<Line[]>([]) // @2022-1009 Constraintしたものを使う
  const [calUsers, setCalUsers] = React.useState<UserType[]>([])
  //
  const fmt = (dt: Date) => format(dt, 'yyyy-MM-dd')
  //
  // socketイベントハンドラの登録解除
  //
  // 'cal' イベントのハンドラを登録、解除する処理、メモ化して使う。
  // useCallback(fn, deps) は useMemo( ()=>fn, deps) と等価。
  // on_cal をその内容を、ここに書かないとだめ。
  // @2022-1009 handel_cal2()を呼んでしまうのだが、これも、この中にかかないとおこられる。....どうするか。
  // ...長いし own useCallback()にくるむ？... とりあえず useCallbackでくるんでみた。OKのようだ。しかしややこしい。
  /*
    The 'handle_cal2' function makes the dependencies of useCallback Hook (at line 122) 
    change on every render. Move it inside the useCallback callback. 
    Alternatively, wrap the definition of 'handle_cal2' in its own useCallback() Hook  
    react-hooks/exhaustive-deps 
  */

  const handle_cal2= React.useCallback(
    //
    // arg1 関数
    //
    ({cal1, cal2}:{cal1: CalType; cal2: CalType; })=>{

      const days: ConstraintDay[]= []
    //
    // カレンダー●当月分を Date, Line[] の配列にする。
    //
    for( let i= 0; i<cal1.weeks.length; ++i){
      for( let j= 0; j<7; ++j){
        const day: ReservationDay = cal1.weeks[i][j]
        const lines= rsub( {d: day.d, rs: day.rs, user: user, debug:flags.fDebug} )
        days.push({dt: day.d, lines})
        //
        //当月のrsをみておく。
        if(flags.fDebug && 0<day.rs.length){
          p_rows(`@handle_cal2 ${format(day.d, 'yyyy-MMdd')} rs:${day.rs.length}`, day.rs)
        }
      }
    }
    //
    // カレンダー●翌月分を Date, Line[] に追加するが 月末はダブっているのでskipも必要。
    //
    let end= cal1.end
    //
    for( let i= 0; i<cal2.weeks.length; ++i){
      for( let j= 0; j<7; ++j){
        const day: ReservationDay = cal2.weeks[i][j]
        //
        // カレンダーには前月末のデータがあり、ここでは重複をはずしておく。
        //
        if( isBefore(day.d, end)){
          continue
        }
        // 冗長なので翌月分はdebugオフとする
        const lines= rsub( {d: day.d, rs: day.rs, user: user, debug: false} )
        days.push( {dt: day.d, lines})
      }
    }
    //
    // CDaysができたので回して連日制約(ペア)をかけることができる。
    //
    // ●tuya-sogi ペア予約の可否は line.enableだが、 ３日め４日めの可否は line.reason に表現する。
    //  (基本的にペアできなければ不可で、３，４日めはoptionalな UI表現にて)
    //
    let last= days.length -3 // 3日先読みするので
    //
    // tuya-h, ... @2022-1027,追加したので整理 setupを作って分離整理
    //
    for( let i= 0; i < last; ++i){
      //
      // 1. 通夜の可否は翌日の葬儀が空いているかどうかできまる。
      // 2. 通夜+葬儀+安置 ３日めの安置の可否は 通夜の reason に書くことにしよう。場所がないので。
      // 3. 通夜+葬儀+安置+安置 ４媛の安置の可否も、同様に追記するしかない、か。...
      // 2024 L, A 拡張
      //
      const k=setup_sogi_days({
        day_tuya: days[i], day_sogi: days[i+1], day_anti1: days[i+2], day_anti2: days[i+3],
        tag_tuya: 'tuya', tag_sogi: 'sogi', tag_anti: 'anti'
      })

      const h=setup_sogi_days({
        day_tuya: days[i], day_sogi: days[i+1], day_anti1: days[i+2], day_anti2: days[i+3],
        tag_tuya: 'tuya-h', tag_sogi: 'sogi-h', tag_anti: 'anti-h'
      })

      const hall = setup_sogi_hall_days({
        day_tuya: days[i], day_sogi: days[i+1], tag_tuya: 'tuya-l', tag_sogi: 'sogi-l'
      })

      const anti = setup_anti_days({
        day_tuya: days[i], day_sogi: days[i+1], day_anti1: days[i+2], day_anti2: days[i+3],
        tag_tuya: 'tuya-a', tag_sogi: 'sogi-a', tag_anti: 'anti-a'
      })


      //
      // 確認の表示 tuya, tuya-h のreasonをみる。 K, Hの先読み結果は OK 以外の理由 について知る必要がある。
      // (今月だけみる)
      if(flags.fDebug2 && isBefore(days[i].dt, cal1.end)){
        const MMdd= format(days[i].dt, 'MMdd')
        //
        if(k !== 'OK') {console.log(`${MMdd} @handle_cal2 K fails. ${k}`)}
        //
        const reasonK= line_by_tag('tuya', days[i].lines).reason
        console.log(`${MMdd}  reasonK ${reasonK}`)
        //
        if(h !== 'OK') {console.log(`${MMdd} @handle_cal2 H fails. ${h}`)}
        const reasonH= line_by_tag('tuya-h', days[i].lines).reason
        console.log(`${MMdd}  reasonH ${reasonH}`)
        //
        if(hall !== 'OK') {console.log(`${MMdd} @handle_cal2 hall fails. ${hall}`)}
        const reasonHall= line_by_tag('tuya-l', days[i].lines).reason
        console.log(`${MMdd} reasonHall ${reasonHall}`)
        //
        if(anti !== 'OK') {console.log(`${MMdd} @handle_cal2 anti fails. ${anti}`)}
        const reasonAnti= line_by_tag('tuya-a', days[i].lines).reason
        console.log(`${MMdd} reasonAnti ${reasonAnti}`)

      }
    }
    //
    // 最後に setCdaysして handle_cal2は終わる。
    //
    setCdays(days)
    },
    //
    // handle_cal2の useCallback arg2 依存もの
    //
    [user, flags.fDebug, flags.fDebug2]
  ) // end handle_cal2 の useCallback()

  //
  // 〇setup_sogi_days 先読み
  //
  const setup_sogi_days = ({day_tuya, day_sogi, day_anti1, day_anti2, tag_tuya, tag_sogi, tag_anti}:{
    day_tuya: ConstraintDay;
    day_sogi: ConstraintDay;
    day_anti1: ConstraintDay;
    day_anti2: ConstraintDay;
    tag_tuya: string;
    tag_sogi: string;
    tag_anti: string;
  }) => {

    let msg= 'OK'
    try{
      const tuya = line_by_tag(tag_tuya, day_tuya.lines)
      //
      // 0. 通夜がだめなら先読みは不要(理由は入っているはず)
      //
      if( ! tuya.enable ) throw new Error('すでに通夜は不可です')
      //
      // (通夜は空いているので)翌日の葬儀は空いているか
      //
      const sogi = line_by_tag(tag_sogi, day_sogi.lines)
      //
      // 1. 葬儀が開いていないなら 通夜はできないのである。
      //
      if( ! sogi.enable ){
        tuya.enable= false
        tuya.reason= 'NG:不可 翌日の葬儀が空いてないため'
        throw new Error('翌日の葬儀が空いていないので通夜を不可にしました')
      }
      //
      // 通夜+葬儀 はOKだ。
      //
      tuya.reason= 'OK:翌日葬儀'
      //
      // 2. 三日目の安置も可能か？ 
      //
      const anti1= line_by_tag(tag_anti, day_anti1.lines)
      if( ! anti1.enable ) throw new Error('翌日の葬儀はOKだけど三日目は不可')
      //
      tuya.reason= 'OK:翌日葬儀 OK:三日目安置' // 冗長だけど上書き
      //
      // 3. 四日目の安置も可能か？
      //
      const anti2= line_by_tag(tag_anti, day_anti2.lines)
      if( ! anti2.enable ) throw new Error('翌日と三日目はOKだけど四日目は不可')
      //
      tuya.reason= 'OK:翌日葬儀 OK:三日目安置 OK:四日目安置' // 冗長だけど上書き
      //
    }catch(e: unknown){
      if( e instanceof Error ) msg= e.message
      else console.log(e)
    }
    //
    return msg // 'OK' or エラーメッセージ
  }

  //
  // ●2024 拡張 L, A の 先読み 連携の可否 (これまでは sogi １パタン　tuya, tuya-h の２適応だけだったのだが...)
  // ホール L を追加 
  // 1日葬儀 2日葬儀 1日法事
  // tag: tuya-l sogi-l (anti-l) ... 1日法事は 1日葬儀と同じ制約 なので tuya-l としよう。anti-l は今は使わないけどtagは 設置しておく
  const setup_sogi_hall_days = ({day_tuya, day_sogi, tag_tuya, tag_sogi}:{
    day_tuya: ConstraintDay;
    day_sogi: ConstraintDay;
    tag_tuya: string;
    tag_sogi: string;
  }) : string => {

    let msg='OK'

    try{
      // そもそも
      const tuya = line_by_tag(tag_tuya, day_tuya.lines)
      if( ! tuya.enable ) {throw new Error('すでにホール葬儀は不可です')}
      //
      tuya.reason= 'OK:1日葬儀 OK:1日法事'
      //
      // 2日め
      const sogi = line_by_tag(tag_sogi, day_sogi.lines)
      if( ! sogi.enable ){ throw new Error('当日の葬儀・法事はOKだけど2日めはだめ') }
      //
      tuya.reason= 'OK:1日葬儀 OK:2日葬儀 OK:1日法事 '
      //
      // ホール はここまでわかればよい。
      
    }catch(e: unknown){
      if( e instanceof Error ) {msg= e.message}
      else {msg='NG'; console.log(e)}
    }
      
    return msg
  }

  //
  // ●2024 A 安置室 (内容は anti なのだが tag は葬儀4日パタンとしてこれまでどおり tuya/sogi/anti とする)
  //
  const setup_anti_days = ({day_tuya, day_sogi, day_anti1, day_anti2, tag_tuya, tag_sogi, tag_anti}:{
    day_tuya: ConstraintDay;
    day_sogi: ConstraintDay;
    day_anti1: ConstraintDay;
    day_anti2: ConstraintDay;
    tag_tuya: string;
    tag_sogi: string;
    tag_anti: string;
  }) : string => {

    let msg= 'OK'
    try{
      const tuya = line_by_tag(tag_tuya, day_tuya.lines)
      //
      // 0. 当日がだめ？
      //
      if( ! tuya.enable ) {throw new Error('すでに1日安置は不可です')}
      //
      tuya.reason= 'OK:1日安置'
      //
      // 翌日のは空いているか
      //
      const sogi = line_by_tag(tag_sogi, day_sogi.lines)
      if( ! sogi.enable ){ throw new Error('翌日の葬儀が空いていないので通夜を不可にしました') }
      //
      tuya.reason= 'OK:1日安置 OK:2日安置'
      //
      // 三日目の安置も可能か？ 
      //
      const anti1= line_by_tag(tag_anti, day_anti1.lines)
      if( ! anti1.enable ) throw new Error('三日目は安置不可')
      //
      tuya.reason= 'OK:1日安置 OK:2日安置 OK:3日安置'
      //
      // 四日目の安置も可能か？
      //
      const anti2= line_by_tag(tag_anti, day_anti2.lines)
      if( ! anti2.enable ) throw new Error('四日目は安置不可')
      //
      tuya.reason= 'OK:1日安置 OK:2日安置 OK:3日安置 OK:4日安置'
      //
      
    }catch(e: unknown){
      if( e instanceof Error ) {msg= e.message}
      else {msg='NG'; console.log(e)}
    }

    return msg
  }




  //
  // 〇subscribe_on
  //
  const subscribe_on = React.useCallback(
    ()=>{
      // cal は obsoleted されます。...
      socket.on('cal', (res: _CalType) => {
        console.log('@@@ cal NOT used now.... something wrong!')
      })
      socket.on('cal2', (res: _Cal2Type) => {
        setCalendar(date_it(res.cal1))
        handle_cal2({cal1: date_it(res.cal1), cal2: date_it(res.cal2)})
        //handle2(res)
      })
      socket.on('cal-users', (res: UserType[]) => {
        setCalUsers(res)
      })
    },
    [socket, handle_cal2]
  )
  const subscribe_off = React.useCallback(
    ()=>{
      socket.off('cal') // そのうちobsoleted...
      socket.off('cal2')
      socket.off('cal-users')
    },
    [socket]
  )
  //
  // 〇 update_cal 
  // emitして データ更新する
  // マウント時にこの月の日付データと該当する予約データを 'cal' にてIOサーバーから得る。
  //
  const update_cal = React.useCallback(
    ()=>{
      socket.emit('cal2', {entry}) //@2022-1003
      setItemD(itemDNone)
      setStamp({at: hms()})
    },
    [socket, entry]
  )
  //
  // 〇 update cal users
  //
  const update_cal_users = React.useCallback(
    ()=>{
      socket.emit('cal-users', {entry})
    },
    [socket, entry]
  )
  //
  // 〇マウント時に update_cal を呼びだして(emit calして) weeksを埋める。
  //
  // 取得すれば weeks[]が埋まるので、それまでは Loading.... で応答する。
  // (useEffectはrendering後に行われる)
  React.useEffect(
    ()=>{
      //console.log('@@@ calendar mount')
      subscribe_on()
      update_cal()
      update_cal_users()
      return ()=>{
        //console.log('@@@ calendar unmount')
        subscribe_off()
        setItemD(itemDNone)
      } //!!よくわすれるが　このreturnでは ()=>subscribe_off() のように[もどり]、があってはいけない。{}で。
    }, [subscribe_on, subscribe_off, update_cal, update_cal_users]
  )
  //
  // 〇Loading ... 表示、spinner アイコンまたは文字で。
  //
  const Loading = () => (<>
    <div className="spinner-border text-info" role="status"></div>
    {/* <div><span className="sr-only">Loading...</span></div> */}
  </>)

  //
  // ログインを促す!? --> トップレベル(App)で is_auth()で２分岐宣言的に、しておく。
  // でないと（ここではねると）ちゃんと mount/unmount処理とならないので、ここで跳ね返すとやっかいなことに。
  // (const LoginPlease = () => (<div>ユーザーアイコンからログインしてください。(カレンダーはログイン後に表示されます)</div>)
  //  ...というのをやめた。)
  //

  //
  // @calの応答がkqサーバーからくるが日付値はISO stringでくるのでDate値に変換しておく。
  // emit calして 応答を subscribeしているので (dateのDate化は)ここでの処理とならざるを得ない。
  //
  function date_it(res: _CalType) : CalType{
    //まず weeks [[{d:}],[{d:}], ] 
    const weeks = res.weeks.map( w => 
      w.map( o => ({ ...o, d:new Date(o.d)}) )
    )

    // see kq's @cal
    const start = new Date(res.start)
    const last = new Date(res.last)
    const begin = new Date(res.begin)
    const end = new Date(res.end)
    const endDay = {d: new Date(res.endDay.d), rs: res.endDay.rs}
    return {weeks, start, last, begin, end, endDay}
  }
  //
  // ●get_cday 
  //
  function get_cday(dt: Date, days: ConstraintDay[]): Line[]{
    const found= days.find( dtl => isSameDay( dtl.dt, dt ))
    if(found){
      return found.lines
    }else{
      console.log(`@@@ ${fmt(dt)} NOT in days.`)
      return []
    }
  }

  //
  // ●handle  その日についての詳細画面を表示するため setItemD()する。(日付のクリックのハンドル)
  //
  // 
  // weeks は 以下の通りという前提で...(weekの配列 月５週なら weeks.lengthは5 )
  // この月のカレンダーに必要な Dateの配列
  // [ [第1週の7日分], [第2週の7日分], ... [最終週の7日分] ]
  // かならず日曜日から土曜日まで埋まっている。
  //
  function handle({i, j, o}:{i: number; j: number; o: ReservationDay}){
    //const touch_date= format(new Date(), 'HH:mm:ss.SSS') // touched
    // const next_index = (i: number, j: number) : {w: number; y:number;} =>{
    //   const w = i + Math.floor((j+1) / 7) //週番号
    //   const y = (j+1) % 7 // 曜日
    //   return { w, y }
    // }
    // dayformが開いていて、(閉じられずに) 別のところがクリックされたときはitemDをリセットする。
    if(itemD && itemD.touched){
      setItemD(itemDNone)
      setLines([])
      // lines, lines2も初期化しておく
//      setLines(new_lines(new Date(), user.username))
//      setLines2(new_lines(new Date(), user.username))
      alert('予約情報画面を閉じます。')
      return
    }

    //console.log(`@@@ 今は当日過去もテストのためゆるしているよ。...`)
    //
    // ●カレンダー日付のクリック可能な範囲を決める
    //
    // 制約1 当日はだめ
    // 制約2 過去はだめ
    // -->ただし adminは OKにしよう。
    //
    if(is_admin(user.username)){
      //console.log(`@@@adminはどの日もクリック可能に。`)
    }else{
      if(isToday(o.d)){
        window.alert(`${fmt(o.d)}\n当日の予約はできません`)
        return
      }
      if(isBefore(o.d, new Date())){
        window.alert(`${fmt(o.d)}\n日付が過ぎているので予約できません`)
        return
      }
    }
    //
    // 〇linesを生成する
    //
    //const lines: Line[] = rsub({d: o.d, rs: o.rs, user})//OKの場合昔のパタン
    const lines: Line[] = get_cday(o.d, cdays) // cdaysはrsubが上(calendar)でかかっているはず、全日。
    //
    // 項目情報を設定する、のでdayformが開く。
    //
    setItemD({touched: true, i, j, o})
    setLines( lines )
  }//ここまで handle() ///////////////////////////////////////////
  
  //
  // 〇prev/next/sameMonth (semeはreload用)
  //
  // 前、次、では entryが変わるのでcalendarがマウントされたときと同じ処理になる update_cal 
  // ので簡単だが、同月手動更新では entry が変化しないので面倒、というか冗長。stampの更新とかも。
  // 
  //
  function prevMonth(){
    setEntry( prev => subMonths(prev, 1))
    setItemD(itemDNone)
  }
  function nextMonth(){
    setEntry( prev => addMonths(prev, 1))
    setItemD(itemDNone)
  }
  // (これはもっとReact的な良い方法があるはず...)
  function sameMonth(){
    setTimeout( ()=>socket.emit('cal2', {entry}), 300) // @2022-1003
    setItemD(itemDNone)
    setCalendar(
      {
        weeks:[],
        begin: new Date(),
        end: new Date(),
        start: new Date(),
        last: new Date(),
        endDay: { d: new Date(), rs: []}
      }
    )
    setStamp({at: hms()})
  }
  //
  // ●ハンドラ  ここで定義してdayform部品にわたすもの、「実行」 と 「中止」
  //
  //  ●cancelD
  //
  const cancelD = () => {
    //console.log('submit 中止')
    setItemD(itemDNone)
  }
  //
  // ●submit2  再考 シンプルにして... マルチinsr/delrに対応するため
  //
  // 画面ボタンとしては「予約」「削除」なので submit 単位としては insr/delr として、
  // 条件別に発行する emit としては　ここで判断して insr, insr2, ... delr, delr2, ... となる。
  //
  // いくつか 注...
  // ボタンからは insr だけど emit するときは insr と insr2 がある。
  // emit insr では rowだけでシンプルなコマンド範囲
  // emit insr2 では row, row2 をそろえる必要がある。ペア予約いまのところ tuya-sogi パタンだけ。
  // kr/io_on.ts(140) socket.on('insr2', async ({row1, row2}:
  //
  // emit insr3, 4がこの延長でできるか？
  // delete は dbとしては idがあれば削除できるのだが、通知mailの文章に rowが必要だ。
  // insrについては 葬儀の3, 4日まとめて、のパタンを line.optionにいれて UIから。 lineを引数にした。
  // ここ dayform では calendarから ２か月分の ConstraintDay[] が参照できるので
  // insr, insr2, insr3. insr4 とkqでのコマンドを変えて対応する。それぞれ、 デフォの当日row とそれ以上の
  // ものについては 先読みしたConstraintDayからさがして、ここでわたす。UIから来たリクエストについては
  // すでに制約はクリアされているので探せばよい、当日の dateをもとに next, next2, next3, netxt4日め、というふうに。
  //
  // UIからは submit2 で cmd, line, item_dt の情報が来る。しかない。
  // insrでは  insr insr2 insr3 insr4 をそれぞれ rowをつけて、ConstraintDay[] から追加をさがして、足して、emitする。
  // この情報は rowにははいらないので、line.optionの 情報を見てここで emitしわける。
  // delr については rowに(insr時にlinkが生成されているので) row.linkの内容で delr, delr2, delr3, delr4 を emitする。
  //
  function find_line(tag: string, dt: Date, days: ConstraintDay[]) {
    const found= days.find(dtl => isSameDay(dtl.dt, dt))
    if(! found){
      throw new Error('day not found')
    }
    const line= line_by_tag(tag, found.lines) // tag位置をみつけられなかったら 例外が飛ぶ。
    return line
  }
  //
  // ●insr_tuya
  //
  function insr_tuya({tuya, dt, tag_tuya, tag_sogi, tag_anti}:{
    tuya:Line;
    dt:Date;
    tag_tuya:string;
    tag_sogi:string;
    tag_anti:string;
  }){
    //
    // 以下は tuya tagの場所の分岐処理 2行程
    //
    // 2024 option パタン KHLA   ４パタンでのUIからの line.optionは...
    // 1. 通夜    -->二日 三日 四日
    // 2. 通夜H  -->二日 三日 四日
    // 3. 通夜L  -->1日葬儀 2日葬儀 1日法事
    // 4. 通夜A  -->1日 2日 3日 4日
    //

    // includes()を使いたいので...漢数字、半角、全角...
    // const one: string[]= ['一日', '1日', '１日']
    // const two: string[]= ['二日', '2日', '２日']
    // const three: string[]= ['三日', '3日', '３日']
    // const four: string[]= ['四日', '4日', '４日']
    //--->だめだ 法事とかあるし...

    function days(option: string): number {
      const tes1 = (s: string) => ( 0<=s.indexOf('ー') || 0<=s.indexOf('1') || 0<=s.indexOf('１') )
      const tes2 = (s: string) => ( 0<=s.indexOf('二') || 0<=s.indexOf('2') || 0<=s.indexOf('２') )
      const tes3 = (s: string) => ( 0<=s.indexOf('三') || 0<=s.indexOf('3') || 0<=s.indexOf('３') )
      const tes4 = (s: string) => ( 0<=s.indexOf('四') || 0<=s.indexOf('4') || 0<=s.indexOf('４') )
      if(      tes1(option)) return 1
      else if(tes2(option)) return 2
      else if(tes3(option)) return 3
      else if(tes4(option)) return 4
      else return 0 //エラー
    }
    //
    // 2024 daysを整理
    //
    const sogi_days= days(tuya.option)
    //
//    if(flags.fDebug2){
      console.log('@insr_tuya tag_tuya=', tag_tuya)
      console.log(`@insr_tuya ${format(dt, 'MMdd')} option ${tuya.option} --> 葬儀days ${sogi_days}`)
//    }
    //
    //
    //
    try{
      //
      //  row.link: 2022-10-09+sogi 2022-10-10+anti のように子の情報をlinkとしてから tuyaを emit
      //  line.option: なし or 二日, 三日、四日 の３種類で insr2, insr3, insr4 を emitするために cdaysから さがす。
      switch(sogi_days){

        //
        // 一日パタンは 2024 で、だが。 io-on.ts の 'insr' で普通にOK?
        //
        case 1:{ // self かな。 see io-on.ts line116あたり .on('insr')
          //row.optionに 値は?
          tuya.row.link = tuya.option // 1日葬儀 2日葬儀 1日法事 は dbに書かねば。//これは メールに表示される link
          socket.emit('insr', {row: tuya.row})
          break
        }

        //
        // 二日パタン tuya+sogi これはデフォ (line.optionが空、または'二日'である。)
        //
        case 2:{
          const sogi = find_line(tag_sogi, addDays(dt, 1), cdays)
          tuya.row.link=`${format(addDays(dt, 1), 'yyyy-MM-dd')}+${tag_sogi}`
          socket.emit('insr2',{
            row1: tuya.row,
            row2: sogi.row
          })
        }break

        //
        // 三日パタン  tuya+sogi+anti  emit insr3
        //
        case 3:{
          const sogi = find_line(tag_sogi, addDays(dt, 1), cdays)
          const anti1 = find_line(tag_anti, addDays(dt, 2), cdays)
          tuya.row.link= `\
${format(addDays(dt, 1), 'yyyy-MM-dd')}+${tag_sogi} \
${format(addDays(dt, 2), 'yyyy-MM-dd')}+${tag_anti}`
          socket.emit('insr3',{
            row1: tuya.row,
            row2: sogi.row,
            row3: anti1.row
          })
        }break

        //
        // 四日パタン  tuya+sogi+anti+anti  emit insr4
        //
        case 4:{
          const sogi = find_line(tag_sogi, addDays(dt, 1), cdays)
          const anti1 = find_line(tag_anti, addDays(dt, 2), cdays)
          const anti2 = find_line(tag_anti, addDays(dt, 3), cdays)
          tuya.row.link= `\
${format(addDays(dt, 1), 'yyyy-MM-dd')}+${tag_sogi} \
${format(addDays(dt, 2), 'yyyy-MM-dd')}+${tag_anti} \
${format(addDays(dt, 3), 'yyyy-MM-dd')}+${tag_anti}`
          socket.emit('insr4',{
            row1: tuya.row,
            row2: sogi.row,
            row3: anti1.row,
            row4: anti2.row
          })
        }break

        default:
          console.log(`@@@insr_tuya unknown sogi days.`)
          break
      } // end switch sogi_days

    }catch(e: unknown){
      if( e instanceof Error ) console.log(`@insr_tuya ${e.message}`)
      else console.log(e)
    }
  
  }// end insr_sogi

  //
  // ●submit2 cmdごとに emitを 処理する
  //
  type Submit2Arg = {
    cmd: string;
    line: Line;
    item_dt: Date; // 当該日の日付
  }
  const submit2 = ({cmd, line, item_dt}: Submit2Arg ) : void => {
    if( line.row.user && line.row.user === '' ){
      console.log(`@@@submit2 row.user empty!!! maybe error`)
    }
    //
    const tag= kind2tag(line.row.kind)
    //
    switch(cmd){
      //
      // 予約 insr --> insr, insr2, insr3, insr4 を判断して emit (line.optionから)(row.linkを生成してから発行)
      //
      case 'insr':
        //
        // @2022-1027 -h サポートのため修正 法要室葬儀だ。
        // rowを最大四日かかえるパタンとして tuyaと tuya-hがあり、
        // rowひとつの従来のが その他 insr のemitだ。
        //
        if(tag === 'tuya' ){
          insr_tuya({tuya:line, dt:item_dt, tag_tuya:'tuya', tag_sogi:'sogi', tag_anti:'anti'})
        }
        else if(tag === 'tuya-h'){
          insr_tuya({tuya:line, dt:item_dt, tag_tuya:'tuya-h', tag_sogi:'sogi-h', tag_anti:'anti-h'})
        }
        //2024 new L, A
        else if(tag === 'tuya-l'){
          insr_tuya({tuya:line, dt:item_dt, tag_tuya:'tuya-l', tag_sogi:'sogi-l', tag_anti:'anti-l'})
        }
        else if(tag === 'tuya-a'){
          insr_tuya({tuya:line, dt:item_dt, tag_tuya:'tuya-a', tag_sogi:'sogi-a', tag_anti:'anti-a'})
        }
        //これは 法要9時、とかのやつだろう。
        else {
          socket.emit('insr', {row: line.row})
        }
      break // end insr

      //
      // 削除
      //
      case 'delr':{
        if( ! (tag === 'tuya' || tag === 'tuya-h' || tag === 'tuya-l' || tag === 'tuya-a') ){
          socket.emit('delr', {row: line.row})
          break
        }
        //
        // tuya の削除では一連の葬儀、安置...も削除する (link情報を使うのは今のところ tuyaだけ、なので)
        //
        const rows = []
        rows.push( {...line.row} )
        const links = dt_tag(line.row.link)
        for(const link of links){
          const found= cdays.find( dtl => isSameDay(dtl.dt, link.dt) )
          if(!found){
            console.log(`@@@ submit2 delr tuya fails, next day not found!`)
            break
          }
          const line= line_by_tag(link.tag, found.lines) // tag位置のlineがみつからなければ例外とした
          rows.push( {...line.row} )
        }
        //
        // rows に応じて 削除delr, 2, 3, 4を発行 (see kq/io-on.ts)
        //
        switch(rows.length){
          case 1:
            socket.emit('delr', {row: rows[0]}) // これは linksがあるはずなのにない、場合かな。とりあえず削除はしてみる。
            break
          case 2:
            socket.emit('delr2', {row1: rows[0], row2: rows[1] })
            break
          case 3:
            socket.emit('delr3', {row1: rows[0], row2: rows[1], row3: rows[2] })
            break
          case 4:
            socket.emit('delr4', {row1: rows[0], row2: rows[1], row3: rows[2], row4: rows[3] })
            break
              
          default:
            console.log(`@@@ delr NOT support for ${rows.length} rows.`)
            break
        }
        
      }break //end delr

      case 'updr':{
        const tag = kind2tag(line.row.kind)
        // tuya
        if( tag === 'tuya' || tag === 'tuya-h' ){
          const opt= {
            id: line.row.id,
            tuya: line.row.funeral_tuya,
            owner: line.row.funeral_owner,
            start: line.row.funeral_start,
            end: line.row.funeral_end,
            denomination: line.row.funeral_denomination
          }
          socket.emit('upd-funeral', opt)
        }
        else if( tag.startsWith('hoyo') ){
          const opt= {
            id: line.row.id,
            nenki: line.row.hoyo_nenki,
            misc: line.row.hoyo_misc,
            at: line.row.hoyo_at,
            owner: line.row.hoyo_owner,
            addr: line.row.hoyo_addr,
            phone: line.row.hoyo_phone,
            memo: line.row.memo // @2022-1027 メモ欄を法要の予約確認先に使うので。
          }
          socket.emit('upd-hoyo', opt)
        }
        // 2024 new L, A
        else if( tag === 'tuya-l' ){
          const opt= {
            id: line.row.id,
            owner: line.row.funeral_owner,
            wake: line.row.funeral_wake,
            start: line.row.funeral_start,
            coffin: line.row.coffin_start,
            end: line.row.funeral_end,
          }
          socket.emit('upd-hall', opt)
        }
        else if( tag === 'tuya-a' ){
          const opt= {
            id: line.row.id,
            owner: line.row.funeral_owner,
            memo: line.row.funeral_memo,
            end: line.row.funeral_end,
            denomination: line.row.funeral_denomination
          }
          socket.emit('upd-anti', opt)
        }
        else{
          console.log(`@@@ submit2 updr bad kind ${tag}`)
        }

      }break

      default:
        console.log(`unk ${cmd}`)
        break
    }
      // re-draw
      setTimeout( ()=>sameMonth(), 500)
  }
  //
  // style names
  //
  const sRow='row mx-0' // 行
  const sCol='col colbox p-0 pr-1 darkborder' // カラム pinkborderはデザイン確認用
  const sColPassedBy= 'col colbox p-0 pr-1 passedby greyborder' // 過去カラム
  const sColSelected= 'col colbox p-0 pr-1 selected'
  const sLine0='line0' // 曜日
  const sLine1='line1' // 日
  // const sLine2='line2'
  // const sLine3='line3'
  // const sLine4='line4'
  //
  // ■表示はすべてformatにて。
  // 日付時刻の表示には formatを使わないと date はISO stringからの値つまり UTCである、
  // たとえば ${getMonth(o.d)}などでは 1日のデータでその月は前月になってしまう。だから format(d, 'M') で表示しないとだめ
  // つまり　Date値としてはそれぞれ -9 hoursのがうまっているのである。 sigh...
  // これも通信でやってくるのでこうしておかないとだめだ。数値としては常にUTC 表示で TZ japaneseで。
  //
  // ■bootstrap5では float-right ではなく float-end である。
  // ceterは (たぶん) (なぜか？) text-centerでないと....

  // ここで return <...>してしまうと 何か... mount/unmountがうまく表現できていないようだから....
  // Loadingはいいのか。ログインチェックかな。。。

  //
  // rendering 空
  //
  if(calendar.weeks.length === 0){
    //console.log('@@@ calendar data Loading...')
    return <Loading />
  }
  //
  // ●rendering calendar with reservations
  //
  //  1. ひと月表示部 (@2022-1027,... DayRsの中で表示を変更。法要室葬儀追加のため葬儀パタンが増えた)
  //  2. 手動更新ボタン行
  //
  return (
  <>
    {/* 月カレンダー表示 */}
    <div className="container-fluid" style={s_padding1}><div className="row">

      {/* 前、当月表示、次のボタン行 */}
      <div className="col-3">
        <button className="btn btn-outline-primary" onClick={prevMonth}>前</button>
      </div>
      <div className="col-6">
        <div className="text-center">
          {format(entry, 'yyyy年MM月')}
        </div>
      </div>
      <div className="col-3">
        <div className="float-end">
          <button className="btn btn-outline-primary" onClick={nextMonth}>次</button>
        </div>
      </div>
    </div></div>
      
    {/* 曜日の表示行 */}
    <div className="container-fluid" style={s_padding1}>
      <div className={sRow}>
        <div className={sCol}><div className="sunday">日</div></div>
        <div className={sCol}><div className={sLine0}>月</div></div>
        <div className={sCol}><div className={sLine0}>火</div></div>
        <div className={sCol}><div className={sLine0}>水</div></div>
        <div className={sCol}><div className={sLine0}>木</div></div>
        <div className={sCol}><div className={sLine0}>金</div></div>
        <div className={sCol}><div className="saturday">土</div></div>
      </div>
    </div>

    {/* 週--->日付--->予約内容-->予約実行フォーム */}
    <div className="container-fluid" style={s_padding1}>
    {
    calendar.weeks.map( (w, i) => (
    <div key={i+10}>
      <div key={i} className={sRow}>
      {/* 第1週、2週、.... */}
      {
      w.map( (o, j) => (
        <div key={j}
          className={itemD.i === i && itemD.j === j ? sColSelected:  isBefore(o.d, new Date()) ? sColPassedBy : sCol}
          onClick={()=>handle( {i, j, o} )}>
          <div className={isToday(o.d) ? "today" : sLine1}>
          {isFirstDayOfMonth(o.d)
            ? <span>{format(o.d, 'M月d日')}</span>
            : <span>{format(o.d, 'd')} &nbsp;</span>
          }
          </div>
          {/* weeks --> w --> o で日の箱となる */}
          {/* ここ１日分の箱の中身は４行くらいがきれいなので DayRs部品に出して考えてみる。 */}
          <DayRs user={user} rs={o.rs} />
        </div>
      ))
      }
      </div>
      {
        itemD.i === i ? (
          <DayForm
            lines={lines}
            user={user} item={itemD} calUsers={calUsers}
            flags={flags}
            onCancel={cancelD} onSubmit={submit2}/>
        ) : (
          <div></div>
        )
      }
    </div>
    )) //end weeks.map
    }
    </div>

    {/* 末尾に手動更新ボタンを置く */}
    <div className={sRow}>
      <div> 
        <small>{stamp.at}</small>
        &nbsp;
        <button className="btn btn-outline-info btn-sm float" onClick={sameMonth}>手動更新</button>
        {/* <small>&nbsp;{user.username}</small> */}
      </div>
    </div>
  
  </>
  )
}
